import { Component, Input, OnInit } from '@angular/core';
import { OfficeLocation } from '@twbi/types';
import { fadeIn } from '../../animations';

@Component({
  selector: 'twbi-location-info-block',
  templateUrl: './location-info-block.component.html',
  styleUrls: ['./location-info-block.component.scss'],
  animations: [fadeIn]
})
export class LocationInfoBlockComponent implements OnInit {

  @Input() location: OfficeLocation;

  constructor() {
  }

  ngOnInit() {
  }

}
