import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ABOUT_US, CARRER, DATA_SECURITY, IMPRINT, LOCATIONS, LOCATIONS_ROUTE, NEWS, ROOT, SERVICES } from './routes';
import { StartPageComponent } from '../../pages/start-page/start-page.component';
import { AboutUsPageComponent } from '../../pages/about-us-page/about-us-page.component';
import { ServicesPageComponent } from '../../pages/services-page/services-page.component';
import { CarrerPageComponent } from '../../pages/carrer-page/carrer-page.component';
import { LocationsPageComponent } from '../../pages/locations-page/locations-page.component';
import { DataSecurityPageComponent } from '../../pages/data-security-page/data-security-page.component';
import { ImprintPageComponent } from '../../pages/imprint-page/imprint-page.component';
import { JobDialogComponent } from '../../components/job-dialog/job-dialog.component';
import { NewsPageComponent } from '../../pages/news-page/news-page.component';
import { ErrPageComponent } from '../../pages/err-page/err-page.component';
import { ApplicationDialogComponent } from '../../components/application-dialog/application-dialog.component';
import { ServiceDialogComponent } from "../../components/service-dialog/service-dialog.component";


const routes: Routes = [
  {path: ROOT, component: StartPageComponent, data: {animation: 'a'}},
  {path: ABOUT_US, component: AboutUsPageComponent, data: {animation: 'b'}},
  {path: SERVICES, component: ServicesPageComponent, data: {animation: 'c'}},
  {path: LOCATIONS, redirectTo: LOCATIONS_ROUTE + '/alzey', pathMatch: 'full', data: {animation: '3'}},
  {path: LOCATIONS + '/:slug', component: LocationsPageComponent, data: {animation: 'f'}},
  {path: NEWS, component: NewsPageComponent, data: {animation: 'g'}},
  {path: DATA_SECURITY, component: DataSecurityPageComponent, data: {animation: 'h'}},
  {path: IMPRINT, component: ImprintPageComponent, data: {animation: 'i'}},
  {path: 'job/:slug', component: JobDialogComponent, outlet: 'ol', data: {animation: 'Overlay'}},
  {path: 'service/:slug', component: ServiceDialogComponent, outlet: 'ol', data: {animation: 'Overlay'}},
  {path: 'bewerbung/:slug', component: ApplicationDialogComponent, outlet: 'ol', data: {animation: 'Overlay'}},
  {path: '**', component: ErrPageComponent, data: {animation: 'k'}}
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
