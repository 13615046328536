import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {CmsService} from '../../services/cms/cms.service';
import {map, switchMap} from 'rxjs/operators';
import { JobPosting, Service } from '@twbi/types';

@Component({
  selector: 'twbi-service-dialog',
  templateUrl: './service-dialog.component.html',
  styleUrls: ['./service-dialog.component.scss']
})
export class ServiceDialogComponent implements OnInit {

  service: Service;

  constructor(private cms: CmsService, private router: Router, private route: ActivatedRoute) {
    this.route.paramMap.pipe(
      map((params: ParamMap) => params.get('slug')),
      switchMap(slug => this.cms.getByKey('service', slug))
    ).subscribe((resp: any) => {
      console.log( resp.data );
      this.service = resp.data;
    });
  }

  ngOnInit() {
  }

  public closeOverlay() {
    this.router.navigate([{outlets: {ol: null}}]);
  }

  openApplication() {

    this.router.navigate([{outlets: {"ol": null}}]).then(a => this.router.navigate(['/jobs']));

    //
  }

}
