import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OfficeLocation } from '@twbi/types';
import { fadeInFromLeft } from '../../../../animations';

@Component({
  selector: 'twbi-locations-entry',
  templateUrl: './locations-entry.component.html',
  styleUrls: ['./locations-entry.component.scss'],
  animations: [fadeInFromLeft]
})
export class LocationsEntryComponent implements OnInit {

  @Input() location: OfficeLocation;
  @Input() selected: boolean;

  @Output() gotSelected = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  getIconSrc() {
    if (this.location.is_bo || this.location.is_bo === null) {
      return this.selected ? '/assets/icons/selected-marker.png' : '/assets/icons/marker.png'
    } else {
      return this.selected ? '/assets/icons/selected-grey-marker.svg' : '/assets/icons/grey-marker.svg'
    }
  }

}
