import { Component, Input, OnInit } from '@angular/core';
import { slideInOut } from '../../animations';
import { Service } from '@twbi/types';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Observable } from "rxjs";

@Component({
  selector: 'twbi-service-card',
  templateUrl: './service-card.component.html',
  styleUrls: ['./service-card.component.scss'],
  animations: [slideInOut, trigger('growShrink', [
    state('open', style({height: '*'})),
    state('closed', style({height: '{{height}}'}), {params: {height: '279px'}}),
    transition('open <=> closed', [
      animate('0.5s')
    ]),
  ])]
})

export class ServiceCardComponent implements OnInit {

  @Input() service: Service;
  @Input() openServiceStream: Observable<string>;

  collapsed = true;

  wide = window && window.matchMedia ? window.matchMedia('(min-width: 1570px)') : {matches: true};
  normal = window && window.matchMedia ? window.matchMedia('(min-width: 1200px)') : {matches: true};

  collapsedHeight = this.wide.matches ? '457px' : this.normal.matches ? '300px' : '279px';

  constructor() {}

  ngOnInit() {}

}
